import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import './App.css';
import 'semantic-ui-css/semantic.min.css';

import Nav from './Nav.js';
import Game from './Game.js';
import About from './Components/About';
import FooterContent from './FooterContent';

// const { Header, Content, Footer } = Layout;

// export function increment(deltaTime) {
//     return function update(state) {
//         return {
//             value: state.value + deltaTime / 1000
//         };
//     };
// }

class App extends Component {
	render() {
		return (
			<Router>
				<Segment basic vertical inverted color="grey" style={{ minHeight: '100vh', paddingTop: '0' }}>
					<Nav />

					<Route exact path="/" component={Game} />
					<Route exact path="/about" component={About} />

					<FooterContent />
				</Segment>
			</Router>
		);
	}
}

export default App;
