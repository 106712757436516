import React, { Component } from 'react';
import { Segment, Card } from 'semantic-ui-react';
import Styled from 'styled-components';

const CardContainer = Styled(Card)`
    margin-top: 2rem;
    text-align: center;
`;

export default class About extends Component {
	render() {
		return (
			<CardContainer>
				<Segment inverted>This is a game developed by Sonicrida. To find out more, visit the SubReddit or join the discord!</Segment>
			</CardContainer>
		);
	}
}
